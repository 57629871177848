import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../context/auth.context';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { setLoading, updateSettings } from '../../actions/auth.action'
import i18n from '../../i18n';
import { randomId } from '../../services/utils';
import apiService from '../../services/api';
import { LoginMode } from '../../enums/auth.enum';
import { Link } from "react-router-dom";


const MfaStepComponent: React.FC = () => {

    const api = new apiService()
    const { t } = i18n
    const { email, password, settings, loading, dispatch } = useAuth();
    const [, setFormValidated] = useState(false);

    const [pin, setPin] = useState<string[]>(['', '', '', '', '', '']);
    const [pinCompleted, setPinCompleted] = useState(false)
    const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));
    const formRef = useRef<HTMLFormElement>(null)

    const handleResendCode = async () => {
        try {

            dispatch(setLoading(true));
            dispatch(updateSettings({ ...settings, error: '' }))

            // Make API call to validate email
            const resp = await api.validateCredentials({ email, password: password || '' })

            if (resp.status === 200) {
                dispatch(updateSettings({ ...settings, error: '', passwordConfirmed: true }))
            } else {
                throw new Error();
            }

        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch(updateSettings({ ...settings, error: error.response.data.message }))
            } else {
                dispatch(updateSettings({ error: t('_system_error') }))
            }

        } finally {
            dispatch(setLoading(false));
        }


    }

    // Handle input change for each digit
    const handleChange = async (index: number, value: string) => {
        if (!value.match(/[0-9]/)) {
            return
        }

        const newPin = [...pin];
        const digits = value.split('')
        const digit = digits[digits.length - 1]
        newPin[index] = digit;

        setPin(newPin);

        if (digit !== '') {
            // Move focus to the next input on number key press
            inputRefs.current[index + 1]?.focus();
        }
    };

    // Handle key press to navigate between input fields
    const handleKeyDown = (event: any, index: number) => {

        if (event.key === 'Backspace' && index >= 0) {
            // Move focus to the previous input on Backspace press if current input is empty
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
            inputRefs.current[index - 1]?.focus();
        }
    };


    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {

        setFormValidated(false);
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            try {

                dispatch(setLoading(true));
                dispatch(updateSettings({ ...settings, error: '' }))

                const resp = await api.login({ email, password: password || '', code: pin.join('') })

                if (resp.status === 200) {
                    window.location.reload();
                } else {
                    throw new Error();
                }

            } catch (error: any) {

                if (error.response && error.response.data && error.response.data.message) {
                    dispatch(updateSettings({ ...settings, error: error.response.data.message }))
                } else {
                    dispatch(updateSettings({ error: t('_system_error') }))
                }
                dispatch(setLoading(false));

            }
        }
        setFormValidated(true);
    }

    useEffect(() => {

        if (pin.join('').length === 6) {
            setPinCompleted(true)
            formRef.current?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            )
        } else {
            setPinCompleted(false)
        }

    }, [pin])

    if (settings.emailConfirmed && settings.passwordConfirmed && settings.loginMode === LoginMode.MFA) {
        return (
            <>
                <Form noValidate onSubmit={handleSubmit} ref={formRef} >

                    <div className='d-flex justify-content-around align-items-center gap-2 mt-4 mb-4' >

                        {pin.map((digit, index) => (
                            <Form.Control
                                key={index}
                                aria-label="code"
                                required
                                type="tel"
                                // onChange={(e) => dispatch(updateCode(e.target.value))}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                id={(randomId())}
                                disabled={loading}
                                readOnly={loading}
                                autoFocus={index === 0}
                                ref={(ref: any) => (inputRefs.current[index] = ref)}
                                value={digit}
                                style={{ height: '3rem' }}
                                className='text-center'
                            />
                        ))}
                    </div>

                    <Form.Control.Feedback type="invalid">
                        {pin ? `${t('invalid_field', { field: t('code') })}` : t('field_cannot_be_blank', { field: t('code') })}
                    </Form.Control.Feedback>

                    <div className='text-end help-link'>
                        {t('reqeust_resend_code')}
                        <Button variant="link" disabled={loading} className='p-0 m-0 ms-2' onClick={() => handleResendCode()}>
                            {t('resend')}
                        </Button>
                       
                    </div>


                    <Button variant="primary" type="submit" disabled={loading || !pinCompleted} className="mt-3">
                        {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                        {t('continue')}
                    </Button>

                    <div className='text-center mt-2 help-link'>
                        <Link to="/auth/" className='text-decoration-none' onClick={() => {
                            dispatch(updateSettings({ ...settings, error: '', passwordConfirmed: false }))
                        }}>{t('return_to_sign_in')}</Link>
                    </div>

                </Form>
            </>
        )
    }

    return (<></>)
};

export default MfaStepComponent;