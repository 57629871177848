import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getLocale } from "./services/utils";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/login'
import LogouPage from './pages/logout'
import LogouSuccessPage from './pages/logoutSuccess'
import ForgotPassword from './pages/forgotPassword'
import ForgotPasswordConfirmation from './pages/forgotPasswordConfirmation'
import ResetPassword from './pages/resetPassword'
import Error from './pages/error'
import { AuthProvider } from './context/auth.context'
import { setAppBackground } from "./services/utils";
import { Container, Row, Col, Card } from "react-bootstrap";
import HeaderComponent from "./components/header.component";
import FooterComponent from "./components/footer.component";
import FailedAttemptsComponent from "./components/failedAttempts.component";
import { useCookies } from "react-cookie"
import CookieBannerComponent from "./components/cookieBanner.component";
import StatusPageScriptComponent from "./components/statusPageScript.component";

setAppBackground()

function App() {

    const { i18n } = useTranslation();
    const [cookies] = useCookies(['_lang']);

    useEffect(() => {
        const locale = getLocale(cookies['_lang']);
        i18n.changeLanguage(locale).then(() => { }).catch((e: any) => { console.error(e) });
    }, [i18n, cookies])

    return (
        <AuthProvider>
            <Container className="app-container">
                <Row>
                    <Col xs={{ span: 10, offset: 1 }}>
                        <CookieBannerComponent />
                        <Card className="text-center">
                            <HeaderComponent />
                            <BrowserRouter>
                                <FailedAttemptsComponent />
                                <Routes>
                                    <Route path="/auth/:o" element={<LogouSuccessPage />} />
                                    <Route path="/auth/*" element={<LoginPage />} />
                                    <Route path="/auth/login" element={<LoginPage />} />
                                    <Route path="/auth/logout" element={<LogouPage />} />
                                    <Route path="/auth/forgot" element={<ForgotPassword />} />
                                    <Route path="/auth/resetLinkSent" element={<ForgotPasswordConfirmation />} />
                                    <Route path="/auth/reset" element={<ResetPassword />} />
                                    <Route path="/auth/error" element={<Error />} />
                                </Routes>
                            </BrowserRouter>
                            <FooterComponent />
                        </Card>
                    </Col>
                </Row>
            </Container>
            <StatusPageScriptComponent />
        </AuthProvider>
    );
}

export default App;